
import SunnyNew from '../images/carImages/SunnyNew.jpg'
import Sunny from '../images/carImages/Sunny.jpg'

import AttrageSilver from '../images/carImages/AttrageSilver.jpg'
import AttrageWhite from '../images/carImages/AttrageWhite.jpg'
import Mirage from '../images/carImages/Mirage.jpg'

import Rush from '../images/carImages/Rush.jpg'

import Symbol from '../images/carImages/Symbol.jpg'

import Jac from '../images/carImages/Jac.jpg'



const CarDetails = [
    //  =====    Nissan  =======
    {
        imgUrl: SunnyNew,
        brandName: "Nissan",
        carName: 'Sunny',
        color: "",
        year: "",
    },
    {
        imgUrl: Sunny,
        brandName: "Nissan",
        carName: 'Sunny',
        color: "",
        year: "",
    },
    // ====     Mistubishi =======
    {
        imgUrl: AttrageSilver,
        brandName: "Mitsubishi",
        carName: 'Attrage',
        color: "",
        year: "",
    },
    {
        imgUrl: AttrageWhite,
        brandName: "Mitsubishi",
        carName: 'Attrage',
        color: "",
        year: "",
    },
    {
        imgUrl: Mirage,
        brandName: "Mitsubishi",
        carName: 'Mirage',
        color: "",
        year: "",
    },
    //  ====  Toyota  =======
    {
        imgUrl: Rush,
        brandName: "Toyota",
        carName: 'Rush',
        color: "",
        year: "",
    },
    //  ====  Jac  =======
    {
        imgUrl: Jac,
        brandName: "Jac",
        carName: 'Jac',
        color: "",
        year: "",
    },

    //  ====  Renault  =======
    {
        imgUrl: Symbol,
        brandName: "Renault",
        carName: 'Symbol',
        color: "",
        year: "",
    },


]
export default CarDetails


